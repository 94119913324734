<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" size="medium" style="width: 240px" value-format="yyyy-MM-dd" @change="dateChange">
            </el-date-picker>

            <el-input placeholder="公司名称、联系人" style="width: 300px; margin-left: 10px" size="medium"
              class="form-input-group" v-model="listfilter.fname" @keyup.enter.native="onSearch">
              <el-button slot="append" type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
            </el-input>
          </el-form>
        </div>
        <div class="action-right">
          <el-button type="primary" icon="el-icon-circle-plus-outline" size="medium" @click="onAdd">新增</el-button>
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="运维单位名称" prop="Name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="单位地址" prop="Address" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="公司电话" prop="Tel" width="120"></el-table-column>
      <el-table-column label="单位联系人" prop="Contacts" width="120"></el-table-column>
      <el-table-column label="电话" prop="Mobile" width="120"></el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <router-link :to="'/company/maintainuser?unitid=' + scope.row.Id">
            <el-button type="success" icon="el-icon-user" size="mini">人员管理</el-button>
          </router-link>
          <el-button style="margin-left:10px;" type="success" icon="el-icon-edit" size="mini"
            @click="onEdit(scope.row)">修改</el-button>
          <el-button type="warning" icon="el-icon-error" size="mini" style="margin-left: 10px"
            @click="onListDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange" />

    <el-dialog :title="formtype == 'add' ? '添加运维单位' : '修改运维单位'" :visible.sync="dialogFormVisible" width="750px"
      custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true">
      <maintain-form :formtype="formtype" :detail="activeContractorDetail" @cancel="onFormClose"></maintain-form>
    </el-dialog>
  </div>
</template>
<script>
import { getMaintainList, delMaintain } from "@/api/base";
import MaintainForm from "./maintainForm";
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",
      listfilter: {
        fname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogFormVisible: false,
      activeContractorDetail: "",
      formtype: "add",
    };
  },
  components: {
    MaintainForm, pagination,
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dateChange(value) {
      console.log(value);
      console.log(this.filterDaterange);
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getMaintainList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMaintain(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
    onAdd() {
      this.activeContractorDetail = {};
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.activeContractorDetail = item;
      this.formtype = "edit";
      this.dialogFormVisible = true;
    },
    onFormClose() {
      this.getListData();
      this.dialogFormVisible = false;
    }
  },
  created() {
    this.getListData();
  },
};
</script>